import React, { useEffect, useState } from "react";
import PersonIcon from "../common/core/icons/PersonIcon";
import CustomBarChart from "../components/Charts/CustomBarChart";
import SettingsIcon from "../common/core/icons/SettingsIcon";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UsersIcon from "../common/core/icons/UsersIcon";
import GenderIcon from "../core/icons/GenderIcon";
import StarIcon from "../core/icons/StarIcon";
import { callApi } from "../common/utils/api";
import moment from "moment";

const InsightsPage = () => {
  const [statsData, setStatsdata] = useState([]);

  const [men, setMen] = useState("");
  const [women, setWomen] = useState("");
  const date = new Date();

  const formattedDate = moment(date).tz("Asia/Kolkata").format("MMMM");
  const labels = [
    formattedDate,
    // "February",
    // "March",
    // "April",
    // "May",
    // "June",
    // "July",
  ];
  const getDataByKey = (keyName, data) => {
    if (keyName === "totalUsers") {
      return {
        title: "Total Users",
        icon: <UsersIcon color={"rgb(77, 116, 255)"} />,
        description: `Total Users are ${data[keyName]}.`,
        borderBottomColor: "border-b-blue-600",
        color: "blue-600",
        // textColor: "text-blue-600",
        percentage: 40,
      };
    } else if (keyName === "genderDistribution") {
      return {
        title: "Gender Distribution.",
        icon: <GenderIcon color={"#DC2626"} />,
        description: Object.keys(data[keyName]).map((el, ind) => {
          if (data[keyName][el]._id) {
            return `${data[keyName][el]._id} - ${data[keyName][el].count}${
              ind === 1 ? "" : ", "
            }`;
          } else <></>;
        }),
        borderBottomColor: "border-b-red-600",
        color: "red-600",
        // textColor: "text-red-600",
        percentage: 40,
      };
    } else if (keyName === "ageDistribution") {
      return {
        title: "Age Distribution.",
        icon: <PersonIcon color={"#059669"} />,
        description: (
          <div className="grid grid-cols-1 gap-2 overflow-scroll">
            {Object.keys(data[keyName]).map((el, ind) => {
              if (data[keyName][el]._id) {
                return (
                  <div className="border px-2 py-2 border-transparent border-b-green-600 bg-green-100">
                    <text className="text-[30px]">
                      {`${data[keyName][el]._id} Yrs - ${data[keyName][el].count}, `}
                    </text>
                  </div>
                );
              } else <></>;
            })}
          </div>
        ),
        borderBottomColor: "border-b-green-600",
        color: "green-600",
        // textColor: "text-green-600",
        percentage: 40,
      };
    } else if (keyName === "acceptedRequestsCount") {
      return {
        title: "Accepted Requests Count.",
        icon: <StarIcon color={"#D97706"} />,
        description: `Total Accepted Requests are ${data[keyName]}.`,
        borderBottomColor: "border-b-yellow-600",
        color: "yellow-600",
        // textColor: "text-yellow-600",
        percentage: 40,
      };
    } else if (keyName === "combinedDailyActivities") {
      return {
        title: "Combined Daily Activities",
        icon: <StarIcon color={"rgb(77, 116, 255)"} />,
        description: (
          <div className="grid grid-cols-1 gap-2 overflow-scroll">
            {Object.keys(data[keyName]).map((el, ind) => {
              if (data[keyName][el].day) {
                return (
                  <div className="border px-2 py-2 border-transparent border-b-green-600 bg-blue-100">
                    <text className="text-[30px]">
                      {`Day ${data[keyName][el].day} - ${data[keyName][el].count} Users, `}
                    </text>
                  </div>
                );
              } else <></>;
            })}
          </div>
        ),
        borderBottomColor: "border-b-blue-600",
        color: "blue-600",
        // textColor: "text-blue-600",
        percentage: 40,
      };
    } else {
      return {
        title: "Empty",
        icon: <StarIcon color={"#D97706"} />,
        description: "↗︎ No Data Available",
        borderBottomColor: "border-b-yellow-600",
        color: "yellow-600",
        // textColor: "text-yellow-600",
        percentage: 40,
      };
    }
  };

  const getInsightsData = async () => {
    await callApi({
      endpoint: "api/users/insights/data",
      alert: true,
    })
      .then((res) => {
        let keys = Object.keys(res);
        let newData = [];
        keys.forEach((element, index) => {
          let tempObj = getDataByKey(element, res);
          newData.push(tempObj);
        });
        setStatsdata(newData);
        setMen(res["genderDistribution"][0].count);
        setWomen(res["genderDistribution"][1].count);
      })
      .catch((err) => {
        console.log("🚀 ~ getInsightsData ~ err:", err);
      });
  };

  useEffect(() => {
    (async () => {
      getInsightsData();
    })();
  }, []);

  return (
    <div className="bg-gray-100">
      <div className="grid grid-cols-4 lg:grid-cols-4 mt-0 md:grid-cols-2 grid-cols-1 gap-4 p-6">
        {statsData?.map((element, index) => {
          return (
            <div
              className={
                "p-3 shadow-sm bg-white border-b-4 rounded-[6px] " +
                `${element.borderBottomColor}`
              }
              key={element.title + index}
            >
              <div className="w-full h-full flex flex-col justify-between">
                <div className={`flex justify-between mb-6`}>
                  <div
                    className={`font-medium text-[20px] w-[90%]  text-${element.color}`}
                  >
                    {element.title}
                  </div>
                  <div className={`dark:text-slate-300 `}>{element.icon}</div>
                </div>
                <div className={"text-sm flex justify-between items-center"}>
                  <div className="w-[100%] max-h-[70px] leading-[30px] text-[26px] min-h-[70px] overflow-scroll">
                    <text>{element.description}</text>
                  </div>
                  {/* <div style={{ height: 60, width: 60 }} className="">
                    <CircularProgressbar
                      value={element.percentage}
                      text={`${element.percentage}%`}
                        // styles={buildStyles({
                        //   // Rotation of path and trail, in number of turns (0-1)
                        //   rotation: 0.25,
                        //   // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        //   strokeLinecap: "butt",
                        //   // Text size
                        //   textSize: "18px",
                        //   // How long animation takes to go from one percentage to another, in seconds
                        //   pathTransitionDuration: 0.5,
                        //   // Can specify path transition in more detail, or remove it entirely
                        //   // pathTransition: 'none',

                        //   // Colors
                        //   pathColor: `rgba(62, 152, 199, ${
                        //     element.percentage / 100
                        //   })`,
                        //   textColor: "#f88",
                        //   trailColor: "#d6d6d6",
                        //   backgroundColor: "#3e98c7",
                        // })}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between items-center p-6 pt-0 gap-6">
        <div className="w-[70%] bg-white border p-6 ">
          <div className="flex justify-between items-center border-b-2 mb-6 pb-2 w-[100%]">
            <text className="text-xl font-medium">Traffic Sources</text>
            <button className="bg-yellow-500 p-4 pb-2 pt-2 rounded-lg">
              Action
            </button>
          </div>
          <CustomBarChart
            datasets1={[men]} // specific Data
            datasetLabel1="MEN" // Specific data label
            datasets2={[women + women]} // specific Data
            datasetLabel2="WOMEN" // Specific data label
            labels={labels} // all label of the Data
          />
        </div>
        <div className="w-[30%] p-6 bg-white border flex flex-col justify-between items-center">
          <div className="flex justify-between items-center border-b-2 mb-6 pb-2 w-[100%]">
            <text className="text-xl font-medium">Income</text>
            <SettingsIcon color={"lightgray"} />
          </div>
          <div className="flex items-center justify-center w-[100%] ">
            <div
              style={{ width: "300px", height: "300px", position: "relative" }}
            >
              <div // shadow outer
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                }}
              />
              <div //shadow.inner
                style={{
                  position: "absolute",
                  width: "84%",
                  height: "84%",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                  top: "25px",
                  left: "25px",
                }}
              />
              <CircularProgressbar
                value={70}
                text={`${70}%`}
                styles={buildStyles({
                  // Gradient for the pathColor
                  pathColor: `url(#gradient)`,
                  textColor: "#000",
                  trailColor: "transparent",
                  backgroundColor: "#3e98c7",
                })}
              />
              {/* Gradient Definition */}
              <svg style={{ position: "absolute", width: 0, height: 0 }}>
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#ff00ff", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#00ffff", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="flex justify-between items-center border-b-2 mb-6 pb-2 w-[100%] mt-4 text-xl height-fit">
            <text>
              Total Income :
              <span className="font-medium ml-2">{"2,30,000"}</span>
            </text>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-4 lg:grid-cols-4 mt-0 md:grid-cols-2 grid-cols-1 gap-6 p-6 pt-0">
        {statsData.map((element, index) => {
          return (
            <div
              className={
                "p-2 shadow-sm bg-white border-b-4 rounded-[6px] " +
                `${element.borderBottomColor}`
              }
              key={element.title + index}
            >
              <div className="flex justify-between items-center mb-6">
                <div className="dark:text-slate-400 font-medium ">
                  {element.title}
                </div>
                <div className={`dark:text-slate-300 text-}`}>
                  {element.icon}
                </div>
              </div>
              <div>
                <div className={`dark:text-slate-300 text`}>
                  {element.value}
                </div>
                <div
                  className={"text-lg flex justify-between items-center gap-2"}
                >
                  <div className="w-[80%] truncate ">
                    <text>{element.description}</text>
                  </div>
                  <div style={{ height: 60, width: 60 }} className="">
                    <CircularProgressbar
                      value={element.percentage}
                      text={`${element.percentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
      {/* <text>Target Section</text>
      <div className="grid grid-cols-4 lg:grid-cols-4 mt-0 md:grid-cols-2 grid-cols-1 gap-6 p-6 pt-0">
        {statsData.map((element, index) => {
          return (
            <div
              className={
                "p-2 shadow-sm bg-white border-b-4 rounded-[6px] " +
                `${element.borderBottomColor}`
              }
              key={element.title + index}
            >
              <div className="flex justify-between items-center mb-6">
                <div className="dark:text-slate-400 font-medium ">
                  {element.title}
                </div>
                <div className={`dark:text-slate-300 text-}`}>
                  {element.icon}
                </div>
              </div>
              <div>
                <div className={`dark:text-slate-300 text`}>
                  {element.value}
                </div>
                <div
                  className={"text-lg flex justify-between items-center gap-2"}
                >
                  <div className="w-[80%] truncate ">
                    <text>{element.description}</text>
                  </div>
                  <div style={{ height: 60, width: 60 }} className="">
                    <CircularProgressbar
                      value={element.percentage}
                      text={`${element.percentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default InsightsPage;
