import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./common/store";
import  { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <>
            <Routing />
            <Toaster />
          </>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
