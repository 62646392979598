import React from "react";

function CloseEye( {color="black" , size=28}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size} >
      <path fill={color} d="M17.883 19.297A10.949 10.949 0 0112 21c-5.392 0-9.878-3.88-10.818-9A10.982 10.982 0 014.52 5.935L1.394 2.808l1.414-1.414 19.799 19.798-1.414 1.415-3.31-3.31zM5.936 7.35A8.965 8.965 0 003.223 12a9.005 9.005 0 0013.201 5.838l-2.028-2.028A4.5 4.5 0 018.19 9.604L5.936 7.35zm6.978 6.978l-3.242-3.241a2.5 2.5 0 003.241 3.241zm7.893 2.265l-1.431-1.431A8.935 8.935 0 0020.778 12 9.005 9.005 0 009.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 01-2.012 4.593zm-9.084-9.084a4.5 4.5 0 014.769 4.769l-4.77-4.77z"></path>
    </svg>
  );
}

export default CloseEye;