import React, { useEffect, useState } from "react";
import { stateConnected } from "../common/store/redux_tools";
import ErrorIcon from "../common/core/icons/ErrorIcon";
import WarningIcon from "../common/core/icons/WarningIcon";
import SuccessIcon from "../common/core/icons/SuccessIcon";
import { callApi } from "../common/utils/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";

const NotificationsPage = (props) => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllNotifications = async () => {
    setLoading(true);
    await callApi({
      endpoint: "api/users/all-notifications",
    })
      .then((res) => {
        setAllNotifications(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <div className="p-6">
      {loading ? (
        <div>
          <text className="text-2xl font-medium w-[10%]">
            <Skeleton
              style={{ width: "20%", height: "30px", marginBottom: "10px" }}
            />
          </text>
          <div className="flex flex-col gap-2 shadow-sm">
            <Skeleton
              style={{
                height: "90px",
                marginBottom: "10px",
                borderRadius: "14px",
              }}
              count={6}
            />
          </div>
        </div>
      ) : props.notifications.length > 0 ? (
        <div>
          <text className="text-2xl font-medium">All Notifications</text>
          <div className="flex flex-col gap-2 shadow-sm">
            {props.notifications.map((element, index) => {
              if (element.type === "error") {
                return (
                  <div
                    key={element.message + index}
                    className="flex items-center justify-between p-4 hover:cursor-pointer rounded-xl text-xl border"
                  >
                    <div className="flex gap-3 items-center">
                      <span className="p-4 border border-red-300 rounded-full shadow-sm">
                        <ErrorIcon size={24} color={"rgb(255,0,0,0.5)"} />
                      </span>
                      <text className="text-xl text-gray-700">
                        {element.message}
                      </text>
                    </div>
                    <div className="flex flex-col items-start">
                      <text
                        className={`text-sm font-medium text-gray-500 flex items-center justify-center`}
                      >
                        {moment(element?.ct).format("MMMM Do YYYY, h:mm:ss a")}
                      </text>
                    </div>
                  </div>
                );
              } else if (element.type === "warning") {
                return (
                  <div
                    key={element.message + index}
                    className="flex items-center justify-between p-4 hover:cursor-pointer rounded-xl text-xl border"
                  >
                    <div className="flex gap-3 items-center">
                      <span className="p-4 border border-yellow-300 rounded-full shadow-sm">
                        <WarningIcon size={24} color={"rgb(253, 230, 138,1)"} />
                      </span>
                      <text className="text-xl text-gray-700 truncate">
                        {element.message}
                      </text>
                    </div>
                    <div className="flex flex-col items-start">
                      <text
                        className={`text-sm font-medium text-gray-500 flex items-center justify-center`}
                      >
                        {moment(element?.ct).format("MMMM Do YYYY, h:mm:ss a")}
                      </text>
                    </div>
                  </div>
                );
              } else if (element.type === "success") {
                return (
                  <div
                    key={element.message + index}
                    className="flex items-center justify-between p-4 hover:cursor-pointer rounded-xl text-xl border"
                  >
                    <div className="flex gap-3 items-center">
                      <span className="p-4 border border-green-300 rounded-full shadow-sm">
                        <SuccessIcon size={24} color={"rgb(0,255,0,0.5)"} />
                      </span>
                      <text className="text-xl text-gray-700 truncate">
                        {element.message}
                      </text>
                    </div>
                    <div className="flex flex-col items-start">
                      <text
                        className={`text-sm font-medium text-gray-500 flex items-center justify-center`}
                      >
                        {moment(element?.ct).format("MMMM Do YYYY, h:mm:ss a")}
                      </text>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="p-2 flex flex-col items-center justify-center min-h-[550px]">
          <text className="mb-2 text-2xl text-blue-600 font-medium text-center">
            No Data Available
          </text>
          <div className="w-[500px] h-[400px]">
            <img
              src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1702977280~exp=1702977880~hmac=86430ea27889250ad889d8c018ba863436a4c5b088bd2fb44109857566dd726a"
              alt=""
              srcset=""
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default stateConnected(NotificationsPage);
