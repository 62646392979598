import React from "react";

const CustomInput = ({
  placeholder,
  value,
  onChange,
  type = "text",
  autoFocus = false,
  defaultValue = value,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      className="transition block w-full pl-4 h-[40px] rounded-[4px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-blue-600 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
      defaultValue={defaultValue}
    />
  );
};

export default CustomInput;
