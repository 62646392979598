import React from "react";
const SettingsIcon = ({ color, size = 18 }) => {
  return (
    <div className="tools-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill={color ? color : "currentColor"}
          d="M1.784 13.453a8.348 8.348 0 01-.815-1.939A2.5 2.5 0 00.97 7.06a8.324 8.324 0 012.084-3.615 2.5 2.5 0 003.86-2.228 8.325 8.325 0 014.172.002 2.5 2.5 0 003.86 2.226c.482.492.91 1.051 1.27 1.675.36.624.631 1.275.816 1.939a2.5 2.5 0 00-.002 4.455 8.325 8.325 0 01-2.084 3.615 2.5 2.5 0 00-3.86 2.228 8.325 8.325 0 01-4.172-.002 2.5 2.5 0 00-3.86-2.226 8.348 8.348 0 01-1.271-1.675zm4.717.163a4.16 4.16 0 011.875 2.308c.415.04.833.04 1.249.001a4.16 4.16 0 011.876-2.309 4.16 4.16 0 012.938-.47c.24-.34.45-.703.623-1.082A4.16 4.16 0 0114 9.286c0-1.05.392-2.03 1.06-2.778a6.782 6.782 0 00-.624-1.082 4.161 4.161 0 01-2.936-.47 4.16 4.16 0 01-1.876-2.308 6.656 6.656 0 00-1.248-.002A4.161 4.161 0 016.5 4.956a4.16 4.16 0 01-2.938.47c-.241.34-.45.702-.623 1.082A4.16 4.16 0 014 9.286a4.16 4.16 0 01-1.06 2.778 6.79 6.79 0 00.624 1.082 4.161 4.161 0 012.937.47zm2.5-1.83a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1.667a.833.833 0 100-1.666.833.833 0 000 1.666z"
        ></path>
      </svg>
    </div>
  );
};
export default SettingsIcon;
