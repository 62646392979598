import React from "react";

function ErrorIcon({ color, size = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M10.001 18.333a8.333 8.333 0 110-16.667 8.333 8.333 0 010 16.667zm0-9.512L7.644 6.464 6.466 7.642 8.823 10l-2.357 2.357 1.178 1.179 2.357-2.357 2.357 2.357 1.179-1.179L11.18 10l2.357-2.357-1.179-1.178-2.357 2.357z"
      ></path>
    </svg>
  );
}

export default ErrorIcon;
