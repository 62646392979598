import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const CarouselComponent = React.forwardRef((props, ref) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div>
      <Carousel
        ref={ref}
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlay={false}
        slidesToSlide={1}
        // showDots={false}
        // infinite={true}
        // autoPlaySpeed={1000}
        // customTransition="all .5"
        // transitionDuration={500}
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={props?.deviceType}s
        arrows={false}
        keyBoardControl={true}
        containerClass=""
        dotListClass="custom-dot-list-style"
        itemClass=""
      >
        {props.data?.map((src, index) => {
          return (
            <div
              className="rounded-lg h-[150px] w-[170px] bg-blue-600 bg-opacity-40"
              key={src}
            >
              <img src={src} alt="" className="object-contain w-full h-full" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
});

export default CarouselComponent;
