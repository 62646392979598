import React from "react";

function SuccessIcon({ color, size = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M10.001 18.333a8.333 8.333 0 110-16.667 8.333 8.333 0 010 16.667zm-.83-5l5.892-5.893-1.179-1.178-4.714 4.714-2.357-2.357-1.178 1.178 3.535 3.536z"
      ></path>
    </svg>
  );
}

export default SuccessIcon;
