import { LOG_IN } from "../../constant/strings";

const initialState = {
  admin: {
    token: true,
    role: null,
  },
  notifications: [
    {
      type: "success", // error , warning
      message: "This is Success",
    },
    {
      type: "error", // error , warning
      message: "This is Error",
    },
    {
      type: "warning", // error , warning
      message: "This is Warning",
    },
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return { ...state, admin: { ...state.auth, ...action.value } };

    default:
      return state;
  }
};

export default reducer;
