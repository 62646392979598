import React from "react";

const SearchInput = (props) => {
  const { onChange, value } = props;

  return (
    <div className="flex items-center w-[100%]">
      <div className="relative w-full">
        {/* <input
          type="text"
          id="simple-search"
          className="bg-transparent border-gray-100 text-black text-sm rounded-[50px] focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search items here..."
          required
          onChange={(e) => onChange(e.target.value)}
          value={value}
        /> */}
        {/* <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default SearchInput;
