import React, { useEffect, useRef, useState } from "react";
import LeftIcon from "../common/core/icons/LeftIcon";
import RightIcon from "../common/core/icons/RightIcon";
import { stateConnected } from "../common/store/redux_tools";
import ReactLoading from "react-loading";
import { callApi } from "../common/utils/api";
import { useParams } from "react-router-dom";
import { getBaseURL } from "../common/constant/urls";
import RequestsReceived from "../components/user/RequestsReceived";
import RequestsSent from "../components/user/RequestsSent";
import CarouselComponent from "../components/user/CarouselComponent";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SingleUserPage = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [userImages, setUserImages] = useState([]);
  const navigate = useNavigate();

  const [selected, setSelected] = useState("Received");
  const [options, setOptions] = useState(["Received", "Sent"]);
  const CarouselComponentRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const getSingleUserData = async () => {
    setLoading(true);
    await callApi({
      endpoint: "api/users/" + id,
    })
      .then(async (res) => {
        setUser(res?._doc);
        for (let i = 1; i <= 6; i++) {
          try {
            const response = await axios.get(
              `${getBaseURL()}/api/users/${id}/profile/${i}`
            );
            console.log(`Profile ${i} image data:`, response.data);
            setUserImages((prev) => {
              return [...prev, `${getBaseURL()}/api/users/${id}/profile/${i}`];
            });
            // Process the response or store it as needed
          } catch (error) {
            console.error(`Error fetching profile image ${i}:`, error);
            // Handle errors for individual requests
          }
        }
        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getSingleUserData();
  }, []);

  return loading ? (
    <div className="flex p-6 gap-6 bg-white h-[92%] w-[100%] flex items-center justify-center transition duration-200">
      <ReactLoading
        type={"balls"}
        color={"#2a6cdf"}
        height={"60px"}
        width={"60px"}
      />
    </div>
  ) : (
    <div className="bg-gray-100">
      <div className="w-[60%] bg-transparent pt-6 pb-6 sticky top-9 z-[1]">
        <div className="w-[111%] ml-6 bg-white border rounded-lg">
          <button
            className="p-4 flex items-center"
            onClick={() => {
              navigate(`/users/manage`);
            }}
          >
            <LeftIcon color="lightgray" />
            Back
          </button>
        </div>
      </div>
      <div className="flex p-6 pt-0 gap-6 bg-gray-100 transition duration-400">
        <div className="w-[70%] rounded-[10px]">
          <div className="max-h-[400px] flex h-[400px] rounded-2xl bg-white mb-6">
            <div className="w-[30%]">
              <img
                id="main-profile-img"
                src={`${getBaseURL()}/api/users/${user?._id}/profile/1`}
                alt=""
                className="object-cover w-full h-full rounded-tl-lg rounded-bl-lg max-h-[400px]"
                onError={(event) => {
                  let imge = document.getElementById("main-profile-img");
                  imge.src =
                    user?.g === "Male"
                      ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
                }}
              />
            </div>
            <div className="w-[70%]">
              <div className="h-[64%] p-4">
                <p className="text-[30px] font-medium">{user.n}</p>
                <p className="text-[20px]">{user.bio}</p>
              </div>
              {user?.ints?.length > 0 ? (
                <div className="h-[32%] flex flex-wrap gap-2 overflow-x-auto p-2 mb-4 ml-2 mr-0 items-center">
                  {user?.ints?.map((element, index) => (
                    <div
                      key={element + index}
                      className="bg-blue-500 p-2 rounded-lg"
                      style={{ height: "40px" }}
                    >
                      <span className="text-blue-100 text-medium">
                        {element}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div>N/A</div>
              )}
            </div>
          </div>
          {userImages.length > 0 && (
            <div className="max-h-[300px] rounded-2xl bg-white p-6 mb-6">
              <div className="flex justify-between items-center w-[100%]">
                <text className="text-xl font medium">Photos</text>
                <div className="">
                  <button
                    className="p-2 rounded-sm cursor-pointer"
                    onClick={() => {
                      CarouselComponentRef?.current?.previous();
                    }}
                  >
                    <LeftIcon color={"#2a6cdf"} size={30} />
                  </button>
                  <button
                    className="p-2 rounded-sm cursor-pointer"
                    onClick={() => {
                      CarouselComponentRef?.current?.next();
                    }}
                  >
                    <RightIcon color={"#2a6cdf"} size={30} />
                  </button>
                </div>
              </div>
              <CarouselComponent
                ref={CarouselComponentRef}
                data={[
                  ...userImages,
                  // "https://cdn.britannica.com/82/220482-050-17843B41/Priyanka-Chopra-2020.jpg",
                  // "https://image.stern.de/33418986/t/Yy/v4/w1440/r1/-/priyanka-chopra-jonas.jpg",
                  // "https://www.masala.com/cloud/2023/07/05/My-project-61.jpg",
                  // "https://media.vogue.co.uk/photos/63c6cd1a55e5f579ef51181e/2:3/w_2560%2Cc_limit/GettyImages-1150107995.jpg",
                  // "https://img.etimg.com/photo/98513965/98513965.jpg",
                  // "https://awsimages.detik.net.id/community/media/visual/2022/06/08/priyanka-chopra-6_43.jpeg?w=600&q=90",
                  // "https://pagesix.com/wp-content/uploads/sites/3/2023/03/NYPICHPDPICT000008048479.jpg",
                ]}
              />
            </div>
          )}
          <div className="p-4 rounded-2xl bg-white p-6 mb-6">
            <h2 className="text-2xl font-bold mb-4">About</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="font-medium">Name:</span>
                </div>
                <div>{user?.n ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Gender:</span>
                </div>
                <div>{user?.g ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Date of Birth:</span>
                </div>
                <div>
                  {user?.dob ? new Date(user.dob).toLocaleDateString() : "N/A"}
                </div>

                <div>
                  <span className="font-medium">City:</span>
                </div>
                <div>{user?.addr?.city ?? "N/A"}</div>

                <div>
                  <span className="font-medium">State:</span>
                </div>
                <div>{user?.addr?.state ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Marital Status:</span>
                </div>
                <div>{user?.ms ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Religion:</span>
                </div>
                <div>{user?.rel ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Height:</span>
                </div>
                <div>{user?.ht ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Weight:</span>
                </div>
                <div>{user?.wt ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Education:</span>
                </div>
                <div>
                  {user?.hq ? `${user.hq} at ${user.clg ?? "N/A"}` : "N/A"}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="font-medium">Occupation:</span>
                </div>
                <div>
                  {user?.jt ? `${user.jt} at ${user.cn ?? "N/A"}` : "N/A"}
                </div>

                <div>
                  <span className="font-medium">Company Location:</span>
                </div>
                <div>{user?.htwn ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Working Since:</span>
                </div>
                <div>{user?.sdt ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Annual Salary:</span>
                </div>
                <div>{user?.slr ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Smoking:</span>
                </div>
                <div>{user?.smk ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Drinking:</span>
                </div>
                <div>{user?.drks ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Diet Preference:</span>
                </div>
                <div>{user?.fp ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Bio:</span>
                </div>
                <div>{user?.bio ?? "N/A"}</div>

                <div>
                  <span className="font-medium">Interests:</span>
                </div>
                <div>{user?.ints?.length ? user.ints.join(", ") : "N/A"}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[30%] p-6 pt-0 bg-white flex flex-col h-[500px] rounded-2xl overflow-x-scroll sticky top-20">
          <div className="flex w-[100%] gap-2 sticky top-0 bg-white h-[60px] pt-6 mb-6">
            {options &&
              options?.map((el) => {
                return (
                  <button
                    className={`p-2 ${
                      selected === el
                        ? "border-b-4 border-b-blue-500"
                        : "border-b-4 border-b-transparent"
                    }`}
                    onClick={() => {
                      setSelected(el);
                    }}
                  >
                    {el}
                  </button>
                );
              })}
          </div>
          {selected === "Received" ? <RequestsReceived /> : <RequestsSent />}
        </div>
      </div>
    </div>
  );
};

export default stateConnected(SingleUserPage);
