import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import moment from "moment-timezone";
import Avatar from "react-avatar";
import { callApi } from "../common/utils/api";
import { getBaseURL } from "../common/constant/urls";
import { useNavigate } from "react-router-dom";
import RightSidebar from "../components/sidebar/RightSidebar";
import EditIcon from "../common/core/icons/EditIcon";
import EditUserSidebarContent from "../components/user/EditUserSidebarContent";

const UsersPage = () => {
  const childRef = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [column, setColumn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentvalue] = useState(null);

  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const getColumnWidth = (key) => {
    if (key === "n") {
      return 300;
    } else if (key === "em") {
      return 250;
    } else if (key === "ct" || key === "ut") {
      return 300;
    } else if (key === "idp") {
      return 200;
    } else if (key === "g") {
      return 150;
    } else if (key === "sr") {
      return 100;
    } else if (key === "pv") {
      return 100;
    } else if (key === "ht") {
      return 100;
    } else if (key === "wt") {
      return 100;
    } else if (key === "dob") {
      return 250;
    } else if (key === "actions") {
      return 120;
    } else if (key === "ints") {
      return 500;
    } else {
      return 200;
    }
  };

  const toggleChange = () => {
    setSidebarOpen((prev) => !prev);
    setCurrentvalue(null);
  };

  const getData = async () => {
    await callApi({
      endpoint: "api/users",
    })
      .then((res) => {
        setLoading(true);
        let col = { actions: "Actions", ...res?.columns };
        let rows = res?.data;
        let columnData = Object.keys(col)?.map((key) => {
          return {
            field: key,
            headerName: col[key],
            // width: getColumnWidth(),
            minWidth: getColumnWidth(key),
            // maxWidth: key === "n" ? 350 : 200,
            //   sortable: false,
            //   filterble: false,
            flex: 1,
            renderCell:
              key === "n"
                ? (params) => (
                    <div className="flex items-center gap-4 ml-2">
                      <Avatar name={params.row?.[key]} size="40" round={true} />
                      <text>{params.row?.[key]}</text>
                    </div>
                  )
                : key === "role" && res?.roleMap
                ? (params) => (
                    <div className="flex items-center">
                      {res?.roleMap[params.row?.[key]] === "admin" ? (
                        <>
                          <div className="bg-green-100 text-green-800 px-2 py-2 rounded-full">
                            Administrator
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-sky-100 text-sky-800 px-2 py-2 rounded-full">
                            Incharge
                          </div>
                        </>
                      )}
                    </div>
                  )
                : key === "ct" || key === "ut"
                ? (params) => {
                    const timestamp = params.row?.[key];
                    const date = new Date(timestamp);

                    const formattedDate = moment(date)
                      .tz("Asia/Kolkata")
                      .format("MMMM Do YYYY, h:mm:ss a");

                    return formattedDate;
                  }
                : key === "g"
                ? (params) => {
                    return (
                      <text
                        className={`p-2 rounded-xl w-[100px] text-center ${
                          params.row?.[key] === "Male"
                            ? "bg-green-200"
                            : "bg-red-200"
                        }`}
                      >
                        {" "}
                        {params.row?.[key]}
                      </text>
                    );
                  }
                : key === "dob"
                ? (params) => {
                    const datestamp = params.row?.[key];
                    const date = new Date(datestamp);

                    const formattedDate = moment(date)
                      .tz("Asia/Kolkata")
                      .format("Do MMMM YYYY");

                    return formattedDate;
                  }
                : key === "ints"
                ? (params) => {
                    const interests = params.row?.[key];
                    return (
                      <div className="flex gap-4 overflow-x-scroll">
                        {interests?.map((el) => {
                          return (
                            <div className="bg-indigo-100  px-4 py-2 rounded-lg">
                              {el}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                : key === "actions"
                ? (params) => (
                    <div
                      style={{ zIndex: 20 }}
                      className="flex items-center gap-2"
                    >
                      <button
                        className={
                          "p-2 px-6 flex items-center gap-2 hover:bg-white"
                        }
                        // onClick={() => {
                        //   // Changing in funtion -> onRowClick with the help of if user-edit  - > onRowClick
                        //   // RightSidebarRef?.current?.openSidebar();
                        // }}
                        id={"user-edit"}
                      >
                        Edit
                        <EditIcon size={20} color="gray" />
                      </button>
                    </div>
                  )
                : (params) => params.row?.[col],
          };
        });
        setColumn(columnData);
        const rowsData = rows?.map((user) => {
          let singleObject = {};
          Object.keys(col).forEach((singleRow) => {
            singleObject = { ...singleObject, [singleRow]: user[singleRow] };
          });
          return {
            id: user._id,
            ...singleObject,
          };
        });
        setRows(rowsData);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  // emptying the current value
  useEffect(() => {
    if (!sidebarOpen) {
      setCurrentvalue(null);
    }
  }, [sidebarOpen]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginY: "20px",
        }}
      >
        <div className=" flex gap-2">
          <GridToolbarColumnsButton
            sx={{
              // borderRadius: "20px",
              // paddingY: "0px",
              paddingX: "14px",
              paddingY: "4px",
              fontFamily: "Poppins",
              color: "#2a6cdf",
              // border: "1px solid",
              // borderColor: "rgb(44,108,244,0.2)",
              button: {
                bgcolor: "purple",
                padding: "10px",
              },
            }}
          />
          <GridToolbarFilterButton
            sx={{
              // borderRadius: "20px",
              // paddingY: "0px",
              paddingX: "14px",
              paddingY: "4px",
              fontFamily: "Poppins",
              color: "#2a6cdf",
              // border: "1px solid",
              // borderColor: "rgb(44,108,244,0.2)",
              button: {
                bgcolor: "purple",
                padding: "10px",
              },
            }}
          />
          {/* <GridToolbarDensitySelector /> */}
          <GridToolbarExport
            sx={{
              // borderRadius: "20px",
              // paddingY: "0px",
              paddingX: "14px",
              paddingY: "4px",
              fontFamily: "Poppins",
              color: "#2a6cdf",
              // border: "1px solid",
              // borderColor: "rgb(44,108,244,0.2)",
              button: {
                bgcolor: "purple",
                padding: "10px",
              },
            }}
          />
        </div>
        <div className="flex">
          <GridToolbarQuickFilter
            sx={{
              width: "400px",
              marginRight: "20px",
              // border: "1px solid lightgray",
              // borderColor: "rgb(44,108,244,0.4)",
              borderRadius: "6px",
              paddingY: "0px",
              paddingX: "14px",
              input: {
                bgcolor: "white",
                padding: "10px",
              },
              svg: {
                fill: "rgb(44,108,244,0.4)",
              },
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <RightSidebar
        sidebarContent={
          <EditUserSidebarContent
            toggleSidebar={toggleChange}
            currentValue={currentValue}
            getData={getData}
          />
        }
        sidebarOpen={sidebarOpen}
        onSetSidebarOpen={onSetSidebarOpen}
      />
      <h2 className="mt-4 ml-4 text-xl">Manage Users</h2>
      <DataGrid
        apiRef={childRef}
        rows={rows}
        columns={column}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        onRowClick={(params, event) => {
          if (
            event.target.id === "user-edit" ||
            event.target.tagName === "svg" ||
            event.target.tagName === "path"
          ) {
            setSidebarOpen(true);
            setCurrentvalue(params);
          } else {
            navigate(`/users/manage/${params.id}`);
          }
        }}
        loading={loading}
        density="comfortable"
        slots={{
          toolbar: CustomToolbar,
          //  noRowsOverlay: <>No Data Available</>
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            // printOptions: { disableToolbarButton: true },
            // csvOptions: { disableToolbarButton: true },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        // disableColumnFilter
        // disableColumnSelector
        // disableDensitySelector
        disableRowSelectionOnClick
        checkboxSelection={false}
        sx={{
          // padding: 2,
          // margin: 2,
          fontSize: "16px",
          fontFamily: "Lato",
          height: "calc(100vh - 60px)",
          boxShadow: 0,
          border: 0,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {},
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "rgb(44,108,244,0.8)",
            // color: "white",
            // fill: "white",
            // accentColor: "white",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(224, 231, 255, 1)",
            fontWeight: "bold",
            width: "500px",
          },
          "& .MuiDataGrid-row": {
            height: "60px",
            cursor: "pointer",
            color: "black",
          },
        }}
      />
    </div>
  );
};

export default UsersPage;
