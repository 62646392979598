import DisplayIcon from "../core/icons/DisplayIcon";
import HomeIcon from "../core/icons/HomeIcon";
import LocationIcon from "../core/icons/LocationIcon";
import PeopleIcon from "../core/icons/PeopleIcon";
import UsersIcon from "../core/icons/UsersIcon";

export const LOG_IN = "LOG_IN";

export const NETWORK_ERROR = "NETWORK_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";

// Shape Types
export const RECTANGLE = "RECTANGLE";
export const CIRCLE = "CIRCLE";
export const LINE = "LINE";
export const TEXT = "TEXT";
export const IMAGE = "IMAGE";

// Action Types
export const ADD_RECTANGLE = "ADD_RECTANGLE";
export const UPDATE_RECTANGLE = "UPDATE_RECTANGLE";
export const DELETE_RECTANGLE = "DELETE_RECTANGLE";

export const ADD_CIRCLE = "ADD_CIRCLE";
export const UPDATE_CIRCLE = "UPDATE_CIRCLE";
export const DELETE_CIRCLE = "DELETE_CIRCLE";

export const ADD_LINE = "ADD_LINE";
export const UPDATE_LINE = "UPDATE_LINE";
export const DELETE_LINE = "DELETE_LINE";

export const ADD_TEXT = "ADD_TEXT";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const DELETE_TEXT = "DELETE_TEXT";

export const ADD_IMAGE = "ADD_IMAGE";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";

export const ADD_INSTANCE = "ADD_INSTANCE";
export const UPDATE_INSTANCE = "UPDATE_INSTANCE";
export const DELETE_INSTANCE = "DELETE_INSTANCE";

export const UPDATE_CURRENT_INSTANCE = "UPDATE_CURRENT_INSTANCE";

export const sidebarMenus = [
  {
    title: "Home",
    src: "/",
    icon: <HomeIcon size={18} color="black" />,
  },
  {
    title: "Users",
    src: "/users",
    icon: <PeopleIcon size={18} color="black" />,
  },
  {
    title: "Analytics",
    src: "/analytics",
    icon: <PeopleIcon size={18} color="black" />,
  },
  {
    title: "Home",
    src: "/",
    icon: <HomeIcon size={18} color="black" />,
  },
  {
    title: "Users",
    src: "/users",
    icon: <PeopleIcon size={18} color="black" />,
  },
  {
    title: "Analytics",
    src: "/analytics",
    icon: <PeopleIcon size={18} color="black" />,
  },
  {
    title: "Home",
    src: "/",
    icon: <HomeIcon size={18} color="black" />,
  },
  {
    title: "Users",
    src: "/users",
    icon: <PeopleIcon size={18} color="black" />,
  },
  {
    title: "Analytics",
    src: "/analytics",
    icon: <PeopleIcon size={18} color="black" />,
  },
];

export const products = [
  {
    title: "Dashboard",
    src: "/dashboard",
    icon: <HomeIcon size={18} />,
    menuItems: [
      {
        title: "Insights",
        src: "/insights",
        // icon: <HomeIcon size={18} color="black" />,
        menuItems: [],
      },
    ],
  },
  {
    title: "Users",
    src: "/users",
    icon: (
      <svg
        height={18}
        width={18}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M5 8V20H19V8H5ZM5 6H19V4H5V6ZM20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM7 10H11V14H7V10ZM7 16H17V18H7V16ZM13 11H17V13H13V11Z"
        ></path>
      </svg>
    ),
    menuItems: [
      {
        title: "Manage",
        src: "/manage",
        icon: <HomeIcon size={18} />,
        menuItems: [],
      },
      {
        title: "Requests",
        src: "/requests",
        icon: (
          <svg
            height={18}
            width={18}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M13 21V23.5L10 21.5L7 23.5V21H6.5C4.567 21 3 19.433 3 17.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V20C21 20.5523 20.5523 21 20 21H13ZM13 19H19V16H6.5C5.67157 16 5 16.6716 5 17.5C5 18.3284 5.67157 19 6.5 19H7V17H13V19ZM19 14V4H6V14.0354C6.1633 14.0121 6.33024 14 6.5 14H19ZM7 5H9V7H7V5ZM7 8H9V10H7V8ZM7 11H9V13H7V11Z"
            ></path>
          </svg>
        ),
        menuItems: [],
      },
    ],
  },
  // {
  //   title: "Requests",
  //   src: "/requests",
  //   icon: (
  //     <svg
  //       height={18}
  //       width={18}
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 24 24"
  //     >
  //       <path
  //         fill="currentColor"
  //         d="M13 21V23.5L10 21.5L7 23.5V21H6.5C4.567 21 3 19.433 3 17.5V5C3 3.34315 4.34315 2 6 2H20C20.5523 2 21 2.44772 21 3V20C21 20.5523 20.5523 21 20 21H13ZM13 19H19V16H6.5C5.67157 16 5 16.6716 5 17.5C5 18.3284 5.67157 19 6.5 19H7V17H13V19ZM19 14V4H6V14.0354C6.1633 14.0121 6.33024 14 6.5 14H19ZM7 5H9V7H7V5ZM7 8H9V10H7V8ZM7 11H9V13H7V11Z"
  //       ></path>
  //     </svg>
  //   ),
  //   menuItems: [],
  // },
];
