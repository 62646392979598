import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "./SearchInput";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import LogoutIcon from "../common/core/icons/LogoutIcon";
import { stateConnected } from "../common/store/redux_tools";
import SettingsIcon from "../common/core/icons/SettingsIcon";
import PersonIcon from "../common/core/icons/PersonIcon";
import ErrorIcon from "../common/core/icons/ErrorIcon";
import SuccessIcon from "../common/core/icons/SuccessIcon";
import WarningIcon from "../common/core/icons/WarningIcon";

const Header = (props) => {
  const {
    handleChange,
    search,
    setSearch,
    isProfileOpen,
    setIsProfileOpen,
    isNotificationOpen,
    setIsNotificationOpen,
  } = props;
  const navigate = useNavigate();

  return (
    <div
      style={{ zIndex: 1 }}
      className=" flex justify-between align-center h-[60px] sticky top-0 border-b border-gray-200 bg-white shadow-sm"
    >
      <div className="pl-6 flex justify-center align-center">
        <SearchInput onChange={handleChange} value={search} />
      </div>
      <div className="flex justify-between align-center gap-2 p-2">
        {/* Notification */}
        <DropdownMenu.Root
          onOpenChange={(e) => {
            setIsNotificationOpen(e);
          }}
          open={isNotificationOpen}
        >
          <DropdownMenu.Trigger asChild>
            <button
              className="IconButton p-4 rounded-lg pt-3"
              aria-label="Customise options"
            >
              <svg
                width={18}
                height={18}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fill="gray"
                  d="M20 17H22V19H2V17H4V10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10V17ZM18 17V10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10V17H18ZM9 21H15V23H9V21Z"
                ></path>
              </svg>
            </button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="DropdownMenuContent bg-white shadow-lg rounded-[8px] mr-2 mt-0 p-2 w-80 border flex flex-col gap-1"
              sideOffset={5}
              style={{ zIndex: 2 }}
            >
              {props.notifications.length > 0 ? (
                props.notifications.map((element, index) => {
                  if (element.type === "error") {
                    return (
                      <DropdownMenu.Item
                        key={element.message + index}
                        className="p-2 hover:cursor-pointer rounded-lg text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-200"
                      >
                        <div className="flex gap-3 items-center">
                          <span>
                            <ErrorIcon />
                          </span>

                          {element.message}
                        </div>
                      </DropdownMenu.Item>
                    );
                  } else if (element.type === "warning") {
                    return (
                      <DropdownMenu.Item
                        key={element.message + index}
                        className="p-2 hover:cursor-pointer rounded-lg text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-200"
                      >
                        <div className="flex gap-3 items-center">
                          <span>
                            <WarningIcon />
                          </span>

                          {element.message}
                        </div>
                      </DropdownMenu.Item>
                    );
                  } else if (element.type === "success") {
                    return (
                      <DropdownMenu.Item
                        key={element.message + index}
                        className="p-2 hover:cursor-pointer rounded-lg text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-200"
                      >
                        <div className="flex gap-3 items-center">
                          <span>
                            <SuccessIcon />
                          </span>

                          {element.message}
                        </div>
                      </DropdownMenu.Item>
                    );
                  }
                })
              ) : (
                <div className="p-2 text-center text-gray-600">
                  <text className="text-center text-sm text-gray-600">
                    No Notifications
                  </text>
                </div>
              )}
              {props.notifications.length > 0 && (
                <>
                  <DropdownMenu.Separator className="border" />
                  <DropdownMenu.Item
                    onClick={() => {
                      navigate("/notifications");
                    }}
                    className="p-2 text-center hover:bg-gray-200 hover:cursor-pointer rounded-lg text-sm text-gray-400 hover:text-gray-900"
                  >
                    See All
                  </DropdownMenu.Item>
                </>
              )}

              {/* <DropdownMenu.Arrow className="DropdownMenuArrow" /> */}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>

        {/* Profile */}
        <DropdownMenu.Root
          onOpenChange={(e) => {
            setIsProfileOpen(e);
          }}
          open={isProfileOpen}
        >
          <DropdownMenu.Trigger asChild>
            <button
              className="IconButton p-4 rounded-lg pt-3"
              aria-label="Customise options"
            >
              <svg
                width={18}
                height={18}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fill="gray"
                  d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                ></path>
              </svg>
            </button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="DropdownMenuContent bg-white shadow-lg rounded-[8px] pl-2 pr-2 mr-2 mt-0  p-1 w-40 border flex flex-col gap-1"
              sideOffset={5}
              style={{ zIndex: 2 }}
            >
              <DropdownMenu.Item
                onClick={(e) => {
                  console.log("ggggg");
                }}
                className="DropdownMenuItem rounded-lg p-2 hover:bg-gray-200 hover:cursor-pointer text-sm text-gray-400 hover:text-gray-900"
              >
                <div className="flex gap-3 items-center">
                  <span>
                    <PersonIcon />
                  </span>
                  Profile
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={(e) => {
                  console.log("ggggg");
                }}
                className="DropdownMenuItem rounded-lg p-2 hover:bg-gray-200 hover:cursor-pointer text-sm text-gray-400 hover:text-gray-900"
              >
                <div className="flex gap-3 items-center">
                  <span>
                    <SettingsIcon />
                  </span>
                  Settings
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Separator className="DropdownMenuSeparator border" />
              <DropdownMenu.Item
                onClick={(e) => {
                  console.log("ggggg");
                }}
                className="DropdownMenuItem rounded-lg p-2 hover:bg-gray-200 hover:cursor-pointer text-sm text-gray-400 hover:text-gray-900"
              >
                <div className="flex gap-3 items-center">
                  <span>
                    <LogoutIcon />
                  </span>
                  Logout
                </div>
              </DropdownMenu.Item>

              {/* <DropdownMenu.Arrow className="DropdownMenuArrow" /> */}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

export default stateConnected(Header);
