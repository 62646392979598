import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "../../common/core/icons/CloseIcon";

const CustomModal = forwardRef((props, ref) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    // Any logic after modal is opened
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Expose functions to parent component through ref
  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
  }));

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "8px",
      outline: "none",
      padding: "30px",
      minWidth: "100px",
      minHeight: "100px",
      // position: "relative",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent overlay
      // position: "fixed", // Use fixed position
      zIndex: 10, // Set a z-index just below the modal
    },
  };

  return (
    <div className="w-[100%]">
      {props.openButton ? (
        props.openButton
      ) : (
        <button onClick={openModal}>
          <span>Open Modal</span>
        </button>
      )}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={props.contentLabel}
      >
        {props.children}
        <button onClick={closeModal} className="absolute top-0 p-1 right-0">
          <CloseIcon size={24} color={"#000"} />
        </button>
      </Modal>
    </div>
  );
});

export default CustomModal;
