import React, { useEffect, useState } from "react";
import { callApi } from "../../common/utils/api";
import CustomInput from "../CustomInput";
import Error from "../../common/utils/Error";
import {
  colleges,
  companyNames,
  heights,
  hometowns,
  jobTitles,
  motherTongues,
  qualifications,
  religions,
  salaries,
  weights,
} from "../../common/constant/globalVariables";

const EditUserSidebarContent = (props) => {
  const { toggleSidebar, currentValue, getData } = props;

  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      n: {
        title: "Location Name",
        value: currentValue?.row?.n ? currentValue?.row?.n : "",
        placeholder: "IMD, Pashan",
        type: "input",
      },
      pn: {
        title: "Phone Number",
        value: currentValue?.row?.pn ? currentValue?.row?.pn : "",
        placeholder: "address",
        type: "input",
      },
      idp: {
        title: "idp",
        value: currentValue?.row?.idp ? currentValue?.row?.idp : "",
        placeholder: "Dating / Marriage",
        type: "input",
      },
      rp: {
        title: "Relationship",
        value: currentValue?.row?.rp ? currentValue?.row?.rp : "",
        placeholder: "Your's Daughter",
        type: "input",
      },
      g: {
        title: "gender",
        value: currentValue?.row?.g ? currentValue?.row?.g : "",
        placeholder: "Male / Female",
        type: "select",
        options: ["Male", "Female"],
      },
      dob: {
        title: "Date of Birth",
        value: currentValue?.row?.dob ? currentValue?.row?.dob : "",
        placeholder: "Date Of Birth",
        type: "date",
      },
      ms: {
        title: "Marital Status",
        value: currentValue?.row?.ms ? currentValue?.row?.ms : "",
        placeholder: "Maritial Status",
        type: "select",
        options: ["Never Married", "Divorced", "Widowed", "Awaiting Divorce"],
      },
      rel: {
        title: "religion",
        value: currentValue?.row?.rel ? currentValue?.row?.rel : "",
        placeholder: "Religion",
        type: "select",
        options: religions?.map((el) => el.value),
      },
      bio: {
        title: "Biography",
        value: currentValue?.row?.bio ? currentValue?.row?.bio : "",
        placeholder: "Biography",
        type: "input",
      },
      mt: {
        title: "Mother Tongue",
        value: currentValue?.row?.mt ? currentValue?.row?.mt : "",
        placeholder: "Hindi",
        type: "select",
        options: motherTongues.map((el) => el.value),
      },
      sdt: {
        title: "Settle Down In",
        value: currentValue?.row?.sdt ? currentValue?.row?.sdt : "",
        placeholder: "6 months",
        type: "select",
        options: ["6 months", "In a year", "1 - 2 years", "2+ years"],
      },
      htwn: {
        title: "Home Town",
        value: currentValue?.row?.htwn ? currentValue?.row?.htwn : "",
        placeholder: "Bhopal",
        type: "select",
        options: hometowns?.map((el) => el.value),
      },
      // ht: {
      //   title: "Height",
      //   value: currentValue?.row?.ht ? currentValue?.row?.ht : "",
      //   placeholder: "160",
      //   type: "select",
      //   options: heights?.map((el) => el.value),
      // },
      wt: {
        title: "Weight",
        value: currentValue?.row?.wt ? currentValue?.row?.wt : "",
        placeholder: "160",
        type: "select",
        options: weights?.map((el) => el.value.split(" ")[0]),
      },
      hq: {
        title: "Highest Qualificatoions",
        value: currentValue?.row?.hq ? currentValue?.row?.hq : "",
        placeholder: "Bachelor's",
        type: "select",
        options: qualifications?.map((el) => el.value),
      },
      clg: {
        title: "College",
        value: currentValue?.row?.clg ? currentValue?.row?.clg : "",
        placeholder: "Bachelor's",
        type: "select",
        options: colleges?.map((el) => el.value),
      },
      jt: {
        title: "Job Title",
        value: currentValue?.row?.jt ? currentValue?.row?.jt : "",
        placeholder: "Software Engineer",
        type: "select",
        options: jobTitles?.map((el) => el.value),
      },
      cn: {
        title: "Company Name",
        value: currentValue?.row?.cn ? currentValue?.row?.cn : "",
        placeholder: "Tata Consultancy Services",
        type: "select",
        options: companyNames?.map((el) => el.value),
      },
      slr: {
        title: "Salaries",
        value: currentValue?.row?.slr ? currentValue?.row?.slr : "",
        placeholder: "4-7 LPA",
        type: "select",
        options: salaries?.map((el) => el.value),
      },
      fp: {
        title: "Food Prefrence",
        value: currentValue?.row?.fp ? currentValue?.row?.fp : "",
        placeholder: "Vegetarian",
        type: "select",
        options: ["Vegetarian", "Non Vegetarian", "Vegan", "Eggetarian"],
      },
      drks: {
        title: "Drinks",
        value: currentValue?.row?.drks ? currentValue?.row?.drks : "",
        placeholder: "Socially",
        type: "select",
        options: ["Never", "Socially", "Regularly", "Planning to Quit"],
      },
      smk: {
        title: "Smokes",
        value: currentValue?.row?.smk ? currentValue?.row?.smk : "",
        placeholder: "Socially",
        type: "select",
        options: ["Never", "Socially", "Regularly", "Planning to Quit"],
      },
    });
  }, [currentValue]);

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: { ...prevFormData[fieldName], value },
    }));
  };

  const handleSubmit = async (e) => {
    let keys = Object.keys(formData);
    for (let i = 0; i < keys.length; i++) {
      if (formData[keys[i]].value === "") {
        Error(`${formData[keys[i]].title} Cannot be empty `);
        return;
      }
    }
    let dataObject = {};
    keys.forEach((key) => {
      dataObject = { [key]: formData[key]?.value, ...dataObject };
    });

    await callApi({
      method: "POST",
      endpoint: "api/locations",
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        // addRowData(res); // add the data to the data grid row
      })
      .catch((err) => {});
  };
  const handleUpdate = async (e) => {
    let keys = Object.keys(formData);
    let dataObject = {};
    keys.forEach((key) => {
      if (formData[key]?.value) {
        dataObject = { [key]: formData[key]?.value, ...dataObject };
      }
    });
    await callApi({
      method: "PATCH",
      endpoint: `api/users/${currentValue.id}`,
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        // addRowData(res); // add the data to the data grid row
        getData();
      })
      .catch((err) => {});
  };

  return (
    <div className="flex flex-col w-[94%] h-[90%] gap-4 px-2 py-4 fixed overflow-x-hidden">
      {Object.keys(formData)?.map((element) => {
        return formData[element].type === "input" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            <CustomInput
              placeholder={formData[element]?.placeholder}
              value={formData[element]?.value}
              onChange={(e) => handleChange(element, e.target.value)}
            />
          </div>
        ) : formData[element].type === "select" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            {formData[element]?.options ? (
              <select
                className="transition block w-full pl-4 h-[40px] rounded-[4px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-blue-600 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  handleChange(element, e.target.value);
                  console.log(e.target.value);
                }}
                value={formData[element].value}
              >
                {formData[element]?.options.length !== 0 ? (
                  formData[element]?.options?.map((option) => {
                    return <option value={option}>{option}</option>;
                  })
                ) : (
                  <option value={""}>no option</option>
                )}
              </select>
            ) : (
              <>
                Pass the key name options which is array of string in the object
                which mention types as select
              </>
            )}
          </div>
        ) : formData[element].type === "date" ? (
          <>
            <label>{formData[element]?.title}</label>
            <input
              datepicker
              datepicker-orientation="bottom right"
              type="date"
              class="transition block w-full pl-4 min-h-[40px] rounded-[4px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-blue-600 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              placeholder="Select date"
              value={formData[element].value.split("T")[0]}
              onChange={(e) =>
                handleChange(element, e.target.value + "T00:00:00.000Z")
              }
            />
          </>
        ) : (
          <>TYPE NOT MATCHED</>
        );
      })}
      <button
        type="submit"
        onClick={currentValue ? handleUpdate : handleSubmit}
        className="fixed -bottom-2 transition mt-4 mb-4 block w-[90%] pl-4 h-[54px] rounded-[4px] font-semibold leading-6 text-white bg-blue-600 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus: hover:bg-blue-700  "
      >
        {currentValue ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default EditUserSidebarContent;
