import { connect } from "react-redux";

import {
  userLogin,
} from "../store/actions/index";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    _userLogin: (data) => {
      dispatch(userLogin(data));
    },
  };
};

export const stateConnected = (Component) => {
  let ExportComponent = (props) => {
    return <Component {...props}></Component>;
  };

  return connect(mapStateToProps, mapDispatchToProps)(ExportComponent);
};
