import React from "react";

function WarningIcon({ color, size = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M10.001 18.333a8.333 8.333 0 110-16.667 8.333 8.333 0 010 16.667zm-.833-5.834v1.667h1.667v-1.667H9.168zm0-6.666v5h1.667v-5H9.168z"
      ></path>
    </svg>
  );
}

export default WarningIcon;
