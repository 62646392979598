import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getBaseURL } from "../../common/constant/urls";
import ReactLoading from "react-loading";

const RequestsReceived = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [requestsReceived, setRequestsReceived] = useState([]);
  const getRequestsReceived = async () => {
    setLoading(true);
    await axios
      .get(getBaseURL() + `/api/users/requests-received/${id}`)
      .then((res) => {
        setRequestsReceived(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRequestsReceived();
  }, []);

  return (
    <div className="h-[100%]">
      {loading ? (
        <div className="p-2 flex items-center justify-center h-[100%] w-full">
          <ReactLoading
            type={"balls"}
            color={"#2a6cdf"}
            height={"60px"}
            width={"60px"}
          />
        </div>
      ) : requestsReceived.length === 0 ? (
        <div className="p-2 flex flex-col items-start justify-center h-[100%] w-full">
          <text className="text-xl text-blue-600 text-left">Empty!</text>
          <div className="w-[100%] m-auto">
            <img
              src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1703141854~exp=1703142454~hmac=6a13d3caefabd41238388fe0c5f5abc4e7ccb5d80c16f345f0d559e0e871b4c0"
              alt=""
              srcset=""
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      ) : (
        requestsReceived?.map((element, index) => {
          return (
            <div className="p-2 flex items-center justify-between gap-2 border border-gray-200 shadow-sm rounded-xl mb-2">
              <div className="flex items-center gap-2">
                <div className="rounded-full">
                  <img
                    src={`${getBaseURL()}/api/users/${
                      element?.sender?._id
                    }/profile/1`}
                    alt="img"
                    srcset=""
                    width={30}
                    height={30}
                    className="rounded-full"
                  />
                </div>
                <text className="text-lg font-medium text-blue-600 ">
                  {element?.sender?.n}
                </text>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default RequestsReceived;
