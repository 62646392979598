import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";

const CustomBarChart = (props) => {
  const { datasets1, datasetLabel1, datasetLabel2, datasets2, labels } = props;
  // Helper function to create a linear gradient fill
  const createGradientFill = (color1, color2) => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
  };

  const gradientFill = createGradientFill("#7367f0", "#26d6eb");

  const chartData = {
    labels,
    datasets: [
      {
        label: datasetLabel1,
        data: datasets1,
        backgroundColor: "#2a6cdf",
        order: 2,
      },
      {
        type: "line",
        label: datasetLabel2,
        data: datasets2,
        backgroundColor: "#7367f0",
        borderColor: "#7367f0",
        order: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true, // Set to false to hide the legend
        position: "bottom",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
    // indexAxis: "y", // horizontal vertical
  };

  useEffect(() => {}, []);

  return (
    <div className="w-full">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default CustomBarChart;
