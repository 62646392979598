import React, { useEffect, useRef, useState } from "react";
import { callApi } from "../common/utils/api";
import { getBaseURL } from "../common/constant/urls";
import ReactLoading from "react-loading";
import CustomModal from "../components/Modal/CustomModal";
import CloseIcon from "../common/core/icons/CloseIcon";
import Error from "../common/utils/Error";
import moment from "moment";
import Avatar from "react-avatar";
import RightIcon from "../common/core/icons/RightIcon";
import SuccessIcon from "../common/core/icons/SuccessIcon";
import axios from "axios";

const RequestsPage = () => {
  const [requests, setRequests] = useState(null);
  const [filteredRequests, setFilteredRequests] = useState(null);
  const [searchRequest, setSearchRequest] = useState("");
  const [currentSelected, setCurrentSelected] = useState(null);

  const [rejectReason, setRejectReason] = useState("");

  const [loading, setLoading] = useState(false);
  const acceptModalRef = useRef();

  const acceptModalOpen = () => {
    acceptModalRef.current.openModal();
  };

  const handleSearchChange = (value) => {
    setSearchRequest(value);
    if (!value) {
      setFilteredRequests([...requests]);
      return;
    }
    let searchFound = requests.filter((element) => {
      return element?.user?.n.toLowerCase().includes(value);
    });
    if (searchFound.length > 0) {
      setFilteredRequests(searchFound);
    } else {
      setFilteredRequests(searchFound);
      setCurrentSelected(null);
      console.log("INSIDE");
    }
  };

  const acceptModalClose = () => {
    acceptModalRef.current.closeModal();
  };

  const rejectModalRef = useRef();

  const rejectModalOpen = () => {
    rejectModalRef.current.openModal();
  };

  const rejectModalClose = () => {
    rejectModalRef.current.closeModal();
  };

  const getRequestsData = async () => {
    setLoading(true);
    await callApi({
      endpoint: "api/users/verification-requests/get",
    })
      .then((res) => {
        if (res.length > 0) {
          setRequests(res);
          setFilteredRequests(res);
        } else {
          setRequests(null);
          setFilteredRequests(null);
        }
        // if (res.length > 0) {
        //   setCurrentSelected({ ...res[0] });
        // }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRequestsData();
  }, []);

  const handleReject = async () => {
    if (rejectReason.length === 0) {
      Error("Add Reason To Reject The Request");
    } else {
      await callApi({
        method: "POST",
        endpoint: "api/users/submit-verification-response/Rejected",
        data: { id: currentSelected?._id, rejectionReason: rejectReason },
        alert: true,
      })
        .then(async (res) => {
          await getRequestsData();
          setRejectReason("");
          rejectModalClose();
        })
        .catch((err) => {});
    }
  };
  const handleConfirm = async () => {
    await callApi({
      method: "POST",
      endpoint: "api/users/submit-verification-response/Accepted",
      data: { id: currentSelected?._id },
    })
      .then(async (res) => {
        await getRequestsData();
      })
      .catch((err) => {});
  };

  const handleDownload = async () => {
    try {
      // let token =s
      const axiosConfig = {
        responseType: "arraybuffer",
        Headers: {
          Accept: "application/json",
          Authorization: `Bearer : `,
        },
      };
      axios.get(currentSelected.url, axiosConfig).then((response) => {
        const url = window.URL.createObjectURL(new Blob(response.data));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sample.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="">
      <div className="">
        {loading ? (
          <div className="flex items-center justify-center min-h-[550px]">
            <ReactLoading
              type={"balls"}
              color={"#2a6cdf"}
              height={"60px"}
              width={"60px"}
            />
          </div>
        ) : requests?.length > 0 ? (
          <div className="flex w-[100%] py-2">
            <div className="flex flex-col gap-4 w-[40%]">
              <div className="flex flex-col sticky top-14 bg-white pt-6 pb-2 bg-gray-0 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-80 border-gray-100">
                <text className="text-2xl font-medium pl-4">Verification Requests</text>
                <div className="flex items-center w-[100%]">
                  <div className="relative w-full flex items-center">
                    <input
                      type="text"
                      className="bg-white mt-2 bg-transparent w-[100%] border-b border-gray-100 text-black text-sm rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block ps-10 p-2.5"
                      placeholder="Search..."
                      onChange={(event) =>
                        handleSearchChange(event.target.value)
                      }
                      value={searchRequest}
                    />
                    <div className="absolute inset-y-0 start-0 flex items-center top-[6px] left-[20px] pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              {filteredRequests.length > 0 ? (
                filteredRequests?.map((element, index) => {
                  return (
                    <div
                      className={`p-4 border-b-1 border-b-gray-400 flex items-center justify-between gap-2 shadow-sm cursor-pointer ${
                        currentSelected?._id === element?._id
                          ? " bg-blue-400"
                          : " hover:bg-blue-200"
                      }`}
                      onClick={() => {
                        setCurrentSelected({ ...element });
                        setRejectReason("");
                      }}
                    >
                      <div className="flex items-center gap-2 w-[55%]">
                        <Avatar
                          name={element?.user?.n}
                          size="40"
                          round={true}
                        />
                        <text
                          className={`text-lg font-medium truncate ${
                            currentSelected?._id === element?._id
                              ? "text-gray-100"
                              : "text-gray-700"
                          }`}
                        >
                          {element?.user?.n}
                        </text>
                      </div>
                      <div
                        className={`p-1 rounded-[6px] ${
                          element.status === "Pending"
                            ? "bg-yellow-500"
                            : element.status === "Accepted"
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        <text className="text-white">{element.status}</text>
                      </div>
                      <div className="flex flex-col items-start">
                        <text
                          className={`text-sm font-medium flex items-center justify-center ${
                            currentSelected?._id === element?._id
                              ? "text-gray-100"
                              : "text-gray-700"
                          }`}
                        >
                          {moment(element?.ct).format("MMM Do YYYY")}
                        </text>
                        <text
                          className={`text-sm font-medium flex items-center justify-center ${
                            currentSelected?._id === element?._id
                              ? "text-gray-100"
                              : "text-gray-700"
                          }`}
                        >
                          {moment(element?.ct).format("LT")}
                        </text>
                      </div>
                    </div>
                  );
                })
              ) : (
                <text className="mb-2 text-2xl text-blue-600 font-medium text-center">
                  No Result Found.
                </text>
              )}
            </div>
            {currentSelected !== null ? (
              <div
                style={{
                  minHeight: "calc(100vh - 130px)",
                  height: "calc(100vh - 130px)",
                }}
                className={`
                flex flex-col justify-between align-center sticky top-24 border-gray-200 bg-white w-[60%]`}
              >
                <div className="relative h-full w-full p-2 m-2">
                  <div className="p-4 flex justify-between items-center gap-2 w-full">
                    <text className={`font-medium truncate text-gray-800`}>
                      {currentSelected?.user?.n}
                    </text>
                    <text
                      className={`text-sm font-medium flex items-center justify-center text-gray-800`}
                    >
                      {`${moment(currentSelected?.ct).format("MMM Do YYYY")}
                      ${moment(currentSelected?.ct).format("LT")}`}
                    </text>
                    <button
                      className="bg-blue-500 text-white p-2 right-0 rounded-lg"
                      onClick={handleDownload}
                    >
                      <span className="flex items-center gap-2">
                        Download
                        <svg
                          height={20}
                          width={20}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {currentSelected?.documentType === "img" ? (
                    <img
                      src={currentSelected?.img}
                      alt=""
                      className="object-cover w-full h-[98%]"
                      srcset=""
                    />
                  ) : (
                    <div className="h-[98%] w-full flex items-center justify-center">
                      <text className="mb-2 text-2xl text-blue-600 font-medium text-center">
                        No Data Available.
                      </text>
                    </div>
                  )}
                </div>
                {currentSelected.status === "Pending" && (
                  <div className="flex gap-2 items-center justify-center p-2 w-[100%] m-2">
                    <CustomModal
                      ref={rejectModalRef}
                      openButton={
                        <button
                          onClick={rejectModalOpen}
                          className="p-2 bg-red-500 rounded-xl pl-10 ml-[50%] pr-10 text-white font-medium flex items-center justify-center gap-2 w-[40%] h-[50px]"
                          aria-label="Update dimensions"
                        >
                          <text className="text-xl">Reject</text>
                          <CloseIcon size={30} />
                        </button>
                      }
                    >
                      <div className="flex flex-col items-center gap-6 w-[400px]">
                        <text className="text-2xl text-red-500 font-medium">
                          Enter Reason For Rejection.
                        </text>
                        <input
                          className="transition block w-full pl-4 min-h-[60px] max-h-[60px] rounded-[4px] border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-blue-600 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          value={rejectReason}
                          onChange={(e) => setRejectReason(e.target.value)}
                        />
                        <button
                          className="p-2 bg-red-500 rounded-xl pl-10 pr-10 text-white font-medium flex items-center justify-center gap-2 w-[100%] h-[60px]"
                          onClick={handleReject}
                        >
                          Confirm Reject
                        </button>
                      </div>
                    </CustomModal>
                    <button
                      onClick={handleConfirm}
                      className="p-2 bg-green-500 rounded-xl mr-[20%] pl-10 pr-10 text-white font-medium flex items-center justify-center gap-2 w-[40%] h-[50px]"
                      aria-label="Update dimensions"
                    >
                      <text className="text-xl">Accept</text>
                      <SuccessIcon size={26} />
                    </button>
                    {/* <CustomModal
                    ref={acceptModalRef}
                    openButton={
                      <button
                        onClick={acceptModalOpen}
                        className="p-2 bg-green-500 rounded-xl pl-10 pr-10 text-white font-medium flex items-center justify-center gap-2 w-[100%] h-[60px]"
                        aria-label="Update dimensions"
                      >
                        <text className="text-xl">Accept</text>
                        <SuccessIcon size={26} />
                      </button>
                    }
                    contentLabel="Example Modal"
                  >
                    <div className="flex flex-col items-center gap-6 w-[400px]">
                      <text className="text-2xl text-green-500 font-medium">
                        Confirm The Verification Process
                      </text>
                      <button
                        className="p-2 bg-green-500 rounded-xl pl-10 pr-10 text-white font-medium flex items-center justify-center gap-2 w-[100%] h-[60px]"
                        onClick={handleConfirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </CustomModal> */}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center align-center h-[80vh] sticky top-24 border-gray-200 bg-white w-[60%]">
                <text className="w-[100%] text-center text-2xl font-medium text-blue-600">
                  Nothing Selected
                </text>
                <div className="w-[60%] m-auto">
                  <img
                    src="https://casaispt.b-cdn.net/wp-content/uploads/2023/07/24487826_6931509.jpg"
                    alt=""
                    srcset=""
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="p-2 flex flex-col items-center justify-center min-h-[550px]">
            <text className="mb-2 text-2xl text-blue-600 font-medium text-center">
              No Data Available
            </text>
            <div className="w-[500px] h-[400px]">
              <img
                src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1702977280~exp=1702977880~hmac=86430ea27889250ad889d8c018ba863436a4c5b088bd2fb44109857566dd726a"
                alt=""
                srcset=""
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestsPage;
