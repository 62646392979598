import React, { useEffect, useState } from "react";
import { products, sidebarMenus } from "../../common/constant/strings";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import LeftIcon from "../../core/icons/LeftIcon";
import RightIcon from "../../core/icons/RightIcon";
import Collapsible from "react-collapsible";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleChange = (searchValue) => {
    setSearch(searchValue);
  };
  const [search, setSearch] = useState("");
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  // const [products, setProducts] = [
  //   {
  //     title: "Dashboards",
  //     src: "/dashboard",
  //     icon: <HomeIcon size={18} color="black" />,
  //     menuItems: [
  //       {
  //         title: "Default",
  //         src: "/default",
  //         // icon: <HomeIcon size={18} color="black" />,
  //         menuItems: [],
  //       },

  //       {
  //         title: "Alternative",
  //         src: "/alternative",
  //         // icon: <HomeIcon size={18} color="black" />,
  //         menuItems: [],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Pages",
  //     src: "/pages",
  //     // icon: <PeopleIcon size={18} color="black" />,
  //     menuItems: [
  //       {
  //         title: "Users",
  //         src: "/users",
  //         icon: <HomeIcon size={18} color="black" />,
  //         menuItems: [],
  //       },

  //       {
  //         title: "User Profile",
  //         src: "/alternative",
  //         // icon: <HomeIcon size={18} color="black" />,
  //         menuItems: [],
  //       },
  //     ],
  //   },
  // ];

  return (
    <div className="flex">
      <div
        className={`${
          isSidebarOpen ? "w-[16%]" : "w-[0%]"
        } z-10 h-[100vh] sticky top-0 duration-300 bg-white p-2 flex flex-col justify-between items-center border  ${
          isSidebarOpen ? "border-gray-100" : "border-transparent"
        }`}
      >
        {/* <button
          style={{ zIndex: 1 }}
          className={`absolute flex justify-center align-center cursor-pointer bg-white -right-4 top-4 p-2 border rounded-full border-r border-gray-200 text-color-primary ${!isSidebarOpen}`}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? <LeftIcon /> : <RightIcon />}
        </button> */}
        <div className="flex gap-x-4 items-center p-2  border-solid border-transparent w-[100%]">
          <h1
            className={`origin-left font-medium text-xl duration-200 text-primary-color font-roboto text-[32px] text-blue-600 ${
              !isSidebarOpen && "scale-0"
            }`}
          >
            InstaMatch Admin
          </h1>
        </div>
        <div className="flex flex-col align-center gap-2 mt-2 h-[100%] w-[100%] overflow-x-hidden overflow-y-scroll ">
          {products?.map((Menu, index) =>
            Menu.menuItems?.length > 0 ? (
              <>
                <Collapsible
                  transitionTime={200}
                  // open={
                  //   pathName.trim("").split("/")[1] === Menu.src.split("/")[1]
                  // }
                  trigger={
                    <div
                      className={`flex gap-2 p-2 justify-between items-center border rounded-lg ${
                        pathName.trim("").split("/")[1] ===
                        Menu.src.split("/")[1]
                          ? " text-blue-100 bg-blue-600 "
                          : " "
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        {Menu.icon && <span>{Menu.icon}</span>}
                        <h2>{Menu.title}</h2>
                      </div>
                      <div>
                        <svg
                          width={18}
                          height={18}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill={"currentColor"}
                            d="M12 15.0006L7.75732 10.758L9.17154 9.34375L12 12.1722L14.8284 9.34375L16.2426 10.758L12 15.0006Z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  }
                >
                  <div className="flex flex-col gap-2 border-l-2 border-gray-200 ml-2 mt-2 pl-2">
                    {Menu.menuItems?.map((menuItem, menuIndex) => {
                      return (
                        <button
                          className={`text-sm w-[100%] p-2 border text-left rounded-lg ${
                            pathName === Menu.src + menuItem.src
                              ? " bg-gray-200"
                              : " border-transparent hover:bg-gray-100"
                          } `}
                          onClick={() => {
                            navigate(`${Menu.src}${menuItem.src}`);
                          }}
                        >
                          <text className="text-left text-sm">
                            {menuItem.title}
                          </text>
                        </button>
                      );
                    })}
                  </div>
                </Collapsible>
              </>
            ) : (
              <button
                className={`flex gap-2 p-2 justify-between items-center border rounded-lg ${
                  pathName.trim("").split("/")[1] === Menu.src.split("/")[1]
                    ? " text-blue-100 bg-blue-600 "
                    : "hover:bg-gray-100 "
                }`}
                onClick={() => navigate(Menu.src)}
              >
                <div className="flex items-center gap-2">
                  {Menu.icon && <span>{Menu.icon}</span>}
                  <h2>{Menu.title}</h2>
                </div>
                <div></div>
              </button>
              // <button
              //   key={index}
              //   className={`flex w-[100%] flex-row align-center cursor-pointer
              // border border-transparent hover:bg-gray-100 rounded-lg
              // text-lg items-center p-y-4 p-3 gap-2
              // ${isSidebarOpen ? "" : "justify-center"} ${
              //     pathName === Menu.src
              //       ? " duration-200 origin-right border border-gray-100 text-blue-600 border-r-2 border-r-blue-600 bg-gray-200"
              //       : " hover:border hover:border-gray-100 bg-gray bg-white duration-200 origin-right"
              //   }`}
              //   onClick={() => navigate(Menu.src)}
              // >
              //   <span className="fill-white">{Menu.icon}</span>
              //   <p
              //     className={`${
              //       !isSidebarOpen && "hidden"
              //     } origin-left text-sm duration-200 ml-2  text-thin`}
              //   >
              //     {Menu.title}
              //   </p>
              // </button>
            )
          )}
        </div>
        {/* <div
          className="flex flex-col align-center gap-2 mt-6 h-[100%] w-[100%] overflow-x-hidden overflow-y-scroll border-2"
        >
          {sidebarMenus?.map((Menu, index) => (
            <button
              key={index}
              className={`flex w-[100%] flex-row align-center cursor-pointer
              border border-transparent
              text-lg items-center p-y-4 p-3 gap-2 
              ${isSidebarOpen ? "" : "justify-center"} ${
                pathName === Menu.src
                  ? " duration-200 origin-right border border-gray-100 text-blue-600 border-r-2 border-r-blue-600 bg-gray-200"
                  : " hover:border hover:border-gray-100 bg-gray bg-white duration-200 origin-right"
              }`}
              onClick={() => navigate(Menu.src)}
            >
              <span className="fill-white">{Menu.icon}</span>
              <p
                className={`${
                  !isSidebarOpen && "hidden"
                } origin-left text-lg duration-200 ml-2  text-thin`}
              >
                {Menu.title}
              </p>
            </button>
          ))}
        </div> */}
      </div>
      <div className={`${isSidebarOpen ? "w-[84%] " : "w-[100%] "} `}>
        <Header
          handleChange={handleChange}
          search={search}
          setSearch={setSearch}
          isProfileOpen={isProfileOpen}
          setIsProfileOpen={setIsProfileOpen}
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
        />
        {props.children}
      </div>
    </div>
  );
};

export default Sidebar;
