export const religions = [
  { label: "Hindu", value: "Hindu" },
  { label: "Muslim", value: "Muslim" },
  { label: "Christian", value: "Christian" },
  { label: "Sikh", value: "Sikh" },
  { label: "Buddhist", value: "Buddhist" },
  { label: "Jain", value: "Jain" },
  { label: "Parsis", value: "Parsis" },
  { label: "Any Religion", value: "Any Religion" },
];

export const motherTongues = [
  { label: "Hindi", value: "Hindi" },
  { label: "Marathi", value: "Marathi" },
  { label: "Bengali", value: "Bengali" },
  { label: "Telugu", value: "Telugu" },
  { label: "Tamil", value: "Tamil" },
  { label: "Kannada", value: "Kannada" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Odia", value: "Odia" },
  { label: "Urdu", value: "Urdu" },
  { label: "Assamese", value: "Assamese" },
  { label: "Maithili", value: "Maithili" },
  { label: "Santali", value: "Santali" },
  { label: "Kashmiri", value: "Kashmiri" },
  { label: "Nepali", value: "Nepali" },
  { label: "Konkani", value: "Konkani" },
  { label: "Sindhi", value: "Sindhi" },
  { label: "English", value: "English" },
  { label: "Other", value: "Other" },
];

export const hometowns = [
  { label: "Mumbai", value: "Mumbai" },
  { label: "Delhi", value: "Delhi" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Ahmedabad", value: "Ahmedabad" },
  { label: "Chennai", value: "Chennai" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Surat", value: "Surat" },
  { label: "Pune", value: "Pune" },
  { label: "Jaipur", value: "Jaipur" },
  { label: "Lucknow", value: "Lucknow" },
  { label: "Kanpur", value: "Kanpur" },
  { label: "Nagpur", value: "Nagpur" },
  { label: "Indore", value: "Indore" },
  { label: "Thane", value: "Thane" },
  { label: "Bhopal", value: "Bhopal" },
  { label: "Visakhapatnam", value: "Visakhapatnam" },
  { label: "Pimpri-Chinchwad", value: "Pimpri-Chinchwad" },
  { label: "Patna", value: "Patna" },
  { label: "Vadodara", value: "Vadodara" },
];
export const heights = [
  { label: "4'0\"", value: "4'0\"" },
  { label: "4'1\"", value: "4'1\"" },
  { label: "4'2\"", value: "4'2\"" },
  { label: "4'3\"", value: "4'3\"" },
  { label: "4'4\"", value: "4'4\"" },
  { label: "4'5\"", value: "4'5\"" },
  { label: "4'6\"", value: "4'6\"" },
  { label: "4'7\"", value: "4'7\"" },
  { label: "4'8\"", value: "4'8\"" },
  { label: "4'9\"", value: "4'9\"" },
  { label: "4'10\"", value: "4'10\"" },
  { label: "4'11\"", value: "4'11\"" },
  { label: "5'0\"", value: "5'0\"" },
  { label: "5'1\"", value: "5'1\"" },
  { label: "5'2\"", value: "5'2\"" },
  { label: "5'3\"", value: "5'3\"" },
  { label: "5'4\"", value: "5'4\"" },
  { label: "5'5\"", value: "5'5\"" },
  { label: "5'6\"", value: "5'6\"" },
  { label: "5'7\"", value: "5'7\"" },
  { label: "5'8\"", value: "5'8\"" },
  { label: "5'9\"", value: "5'9\"" },
  { label: "5'10\"", value: "5'10\"" },
  { label: "5'11\"", value: "5'11\"" },
  { label: "6'0\"", value: "6'0\"" },
  { label: "6'1\"", value: "6'1\"" },
  { label: "6'2\"", value: "6'2\"" },
  { label: "6'3\"", value: "6'3\"" },
  { label: "6'4\"", value: "6'4\"" },
  { label: "6'5\"", value: "6'5\"" },
  { label: "6'6\"", value: "6'6\"" },
  { label: "6'7\"", value: "6'7\"" },
  { label: "6'8\"", value: "6'8\"" },
  { label: "6'9\"", value: "6'9\"" },
  { label: "6'10\"", value: "6'10\"" },
  { label: "6'11\"", value: "6'11\"" },
];

export const weights = [
  { label: "40 kg", value: "40 kg" },
  { label: "41 kg", value: "41 kg" },
  { label: "42 kg", value: "42 kg" },
  { label: "43 kg", value: "43 kg" },
  { label: "44 kg", value: "44 kg" },
  { label: "45 kg", value: "45 kg" },
  { label: "46 kg", value: "46 kg" },
  { label: "47 kg", value: "47 kg" },
  { label: "48 kg", value: "48 kg" },
  { label: "49 kg", value: "49 kg" },
  { label: "50 kg", value: "50 kg" },
  { label: "51 kg", value: "51 kg" },
  { label: "52 kg", value: "52 kg" },
  { label: "53 kg", value: "53 kg" },
  { label: "54 kg", value: "54 kg" },
  { label: "55 kg", value: "55 kg" },
  { label: "56 kg", value: "56 kg" },
  { label: "57 kg", value: "57 kg" },
  { label: "58 kg", value: "58 kg" },
  { label: "59 kg", value: "59 kg" },
  { label: "60 kg", value: "60 kg" },
  { label: "61 kg", value: "61 kg" },
  { label: "62 kg", value: "62 kg" },
  { label: "63 kg", value: "63 kg" },
  { label: "64 kg", value: "64 kg" },
  { label: "65 kg", value: "65 kg" },
  { label: "66 kg", value: "66 kg" },
  { label: "67 kg", value: "67 kg" },
  { label: "68 kg", value: "68 kg" },
  { label: "69 kg", value: "69 kg" },
  { label: "70 kg", value: "70 kg" },
  { label: "71 kg", value: "71 kg" },
  { label: "72 kg", value: "72 kg" },
  { label: "73 kg", value: "73 kg" },
  { label: "74 kg", value: "74 kg" },
  { label: "75 kg", value: "75 kg" },
  { label: "76 kg", value: "76 kg" },
  { label: "77 kg", value: "77 kg" },
  { label: "78 kg", value: "78 kg" },
  { label: "79 kg", value: "79 kg" },
  { label: "80 kg", value: "80 kg" },
  { label: "81 kg", value: "81 kg" },
  { label: "82 kg", value: "82 kg" },
  { label: "83 kg", value: "83 kg" },
  { label: "84 kg", value: "84 kg" },
  { label: "85 kg", value: "85 kg" },
  { label: "86 kg", value: "86 kg" },
  { label: "87 kg", value: "87 kg" },
  { label: "88 kg", value: "88 kg" },
  { label: "89 kg", value: "89 kg" },
  { label: "90 kg", value: "90 kg" },
  { label: "91 kg", value: "91 kg" },
  { label: "92 kg", value: "92 kg" },
  { label: "93 kg", value: "93 kg" },
  { label: "94 kg", value: "94 kg" },
  { label: "95 kg", value: "95 kg" },
  { label: "96 kg", value: "96 kg" },
  { label: "97 kg", value: "97 kg" },
  { label: "98 kg", value: "98 kg" },
  { label: "99 kg", value: "99 kg" },
  { label: "100 kg", value: "100 kg" },
  { label: "101 kg", value: "101 kg" },
  { label: "102 kg", value: "102 kg" },
  { label: "103 kg", value: "103 kg" },
  { label: "104 kg", value: "104 kg" },
  { label: "105 kg", value: "105 kg" },
  { label: "106 kg", value: "106 kg" },
  { label: "107 kg", value: "107 kg" },
  { label: "108 kg", value: "108 kg" },
  { label: "109 kg", value: "109 kg" },
  { label: "110 kg", value: "110 kg" },
  { label: "111 kg", value: "111 kg" },
  { label: "112 kg", value: "112 kg" },
  { label: "113 kg", value: "113 kg" },
  { label: "114 kg", value: "114 kg" },
  { label: "115 kg", value: "115 kg" },
  { label: "116 kg", value: "116 kg" },
  { label: "117 kg", value: "117 kg" },
  { label: "118 kg", value: "118 kg" },
  { label: "119 kg", value: "119 kg" },
  { label: "120 kg", value: "120 kg" },
];

export const qualifications = [
  { label: "High School", value: "High School" },
  { label: "Diploma", value: "Diploma" },
  { label: "Bachelor's Degree", value: "Bachelor's Degree" },
  { label: "Master's Degree", value: "Master's Degree" },
  { label: "Ph.D.", value: "Ph.D." },
  { label: "MBA", value: "MBA" },
  { label: "Engineering", value: "Engineering" },
  { label: "Medical Degree", value: "Medical Degree" },
  { label: "Law Degree", value: "Law Degree" },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "IT Certification", value: "IT Certification" },
  { label: "Other", value: "Other" },
];
export const colleges = [
  {
    label: "Indian Institute of Technology (IIT)",
    value: "Indian Institute of Technology (IIT)",
  },
  {
    label: "National Institute of Technology (NIT)",
    value: "National Institute of Technology (NIT)",
  },
  { label: "Delhi University", value: "Delhi University" },
  {
    label: "Jawaharlal Nehru University",
    value: "Jawaharlal Nehru University",
  },
  { label: "Anna University", value: "Anna University" },
  { label: "Banaras Hindu University", value: "Banaras Hindu University" },
  { label: "Aligarh Muslim University", value: "Aligarh Muslim University" },
  { label: "Manipal University", value: "Manipal University" },
  { label: "Amity University", value: "Amity University" },
  {
    label: "Lovely Professional University",
    value: "Lovely Professional University",
  },
];
export const jobTitles = [
  { label: "Software Engineer", value: "Software Engineer" },
  { label: "Data Analyst", value: "Data Analyst" },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Doctor", value: "Doctor" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Accountant", value: "Accountant" },
  { label: "Marketing Manager", value: "Marketing Manager" },
  { label: "HR Manager", value: "HR Manager" },
  { label: "Graphic Designer", value: "Graphic Designer" },
  { label: "Teacher", value: "Teacher" },
  { label: "Engineer", value: "Engineer" },
  { label: "Architect", value: "Architect" },
  { label: "Research Scientist", value: "Research Scientist" },
  { label: "Nurse", value: "Nurse" },
  { label: "Chef", value: "Chef" },
  { label: "Web Developer", value: "Web Developer" },
  { label: "IT Consultant", value: "IT Consultant" },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Business Analyst", value: "Business Analyst" },
  { label: "Financial Analyst", value: "Financial Analyst" },
  { label: "Journalist", value: "Journalist" },
  { label: "Civil Engineer", value: "Civil Engineer" },
  { label: "Mechanical Engineer", value: "Mechanical Engineer" },
  { label: "Electrical Engineer", value: "Electrical Engineer" },
  { label: "Operations Manager", value: "Operations Manager" },
  { label: "Content Writer", value: "Content Writer" },
  { label: "UI/UX Designer", value: "UI/UX Designer" },
  { label: "Product Manager", value: "Product Manager" },
  { label: "Network Engineer", value: "Network Engineer" },
  { label: "Database Administrator", value: "Database Administrator" },
  { label: "Psychologist", value: "Psychologist" },
  { label: "Biotechnologist", value: "Biotechnologist" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Veterinarian", value: "Veterinarian" },
  { label: "Real Estate Agent", value: "Real Estate Agent" },
  { label: "Event Planner", value: "Event Planner" },
  { label: "Photographer", value: "Photographer" },
  { label: "Fitness Trainer", value: "Fitness Trainer" },
  { label: "Nutritionist", value: "Nutritionist" },
  { label: "Fashion Designer", value: "Fashion Designer" },
  { label: "Interior Designer", value: "Interior Designer" },
  { label: "Public Relations Officer", value: "Public Relations Officer" },
  { label: "Social Media Manager", value: "Social Media Manager" },
  { label: "Data Scientist", value: "Data Scientist" },
  { label: "Aerospace Engineer", value: "Aerospace Engineer" },
  { label: "Environmental Scientist", value: "Environmental Scientist" },
  { label: "Economist", value: "Economist" },
  { label: "Actuary", value: "Actuary" },
  { label: "Statistician", value: "Statistician" },
  { label: "Dentist", value: "Dentist" },
];
export const companyNames = [
  { label: "Infosys", value: "Infosys" },
  { label: "Tata Consultancy Services", value: "Tata Consultancy Services" },
  { label: "Reliance Industries", value: "Reliance Industries" },
  { label: "Wipro", value: "Wipro" },
  { label: "HCL Technologies", value: "HCL Technologies" },
  { label: "Mahindra Group", value: "Mahindra Group" },
  { label: "Aditya Birla Group", value: "Aditya Birla Group" },
  { label: "Larsen & Toubro", value: "Larsen & Toubro" },
  { label: "ICICI Bank", value: "ICICI Bank" },
  { label: "HDFC Bank", value: "HDFC Bank" },
  { label: "Axis Bank", value: "Axis Bank" },
  { label: "Maruti Suzuki", value: "Maruti Suzuki" },
  { label: "Hero MotoCorp", value: "Hero MotoCorp" },
  { label: "Bajaj Auto", value: "Bajaj Auto" },
  { label: "Sun Pharma", value: "Sun Pharma" },
  { label: "Dr. Reddy's Laboratories", value: "Dr. Reddy's Laboratories" },
  { label: "Cipla", value: "Cipla" },
  { label: "Tata Motors", value: "Tata Motors" },
  { label: "Tata Steel", value: "Tata Steel" },
  { label: "JSW Steel", value: "JSW Steel" },
  { label: "Bharti Airtel", value: "Bharti Airtel" },
  { label: "Vodafone Idea", value: "Vodafone Idea" },
  { label: "Flipkart", value: "Flipkart" },
  { label: "Amazon India", value: "Amazon India" },
  { label: "Ola Cabs", value: "Ola Cabs" },
  { label: "Uber India", value: "Uber India" },
  { label: "Zomato", value: "Zomato" },
  { label: "Swiggy", value: "Swiggy" },
  { label: "Paytm", value: "Paytm" },
  { label: "PhonePe", value: "PhonePe" },
  { label: "Byju's", value: "Byju's" },
  { label: "Unacademy", value: "Unacademy" },
  { label: "Vedantu", value: "Vedantu" },
  { label: "OYO Rooms", value: "OYO Rooms" },
  { label: "Airbnb India", value: "Airbnb India" },
  { label: "MakeMyTrip", value: "MakeMyTrip" },
  { label: "BookMyShow", value: "BookMyShow" },
  { label: "Snapdeal", value: "Snapdeal" },
  { label: "Myntra", value: "Myntra" },
  { label: "Nykaa", value: "Nykaa" },
];

export const salaries = [
  { label: "0-2 LPA", value: "0-2 LPA" },
  { label: "2-4 LPA", value: "2-4 LPA" },
  { label: "4-7 LPA", value: "4-7 LPA" },
  { label: "7-10 LPA", value: "7-10 LPA" },
  { label: "10-15 LPA", value: "10-15 LPA" },
  { label: "15-20 LPA", value: "15-20 LPA" },
  { label: "20-30 LPA", value: "20-30 LPA" },
  { label: "30-50 LPA", value: "30-50 LPA" },
  { label: "50+ LPA", value: "50+ LPA" },
];

export const interestsData = {
  cooking: { icon: "food", color: "#FFADAD" },
  swimming: { icon: "swim", color: "#A0E7E5" },
  outdoor: { icon: "pine-tree", color: "#B4F8C8" },
  art: { icon: "palette", color: "#FFC6FF" },
  music: { icon: "music", color: "#FFD6A5" },
  travel: { icon: "airplane", color: "#FFADAD" },
  reading: { icon: "book-open", color: "#FFC4C4" },
  photography: { icon: "camera", color: "#FFAFCC" },
  fitness: { icon: "run", color: "#FFD6A5" },
  yoga: { icon: "yoga", color: "#FBE7C6" },
  hiking: { icon: "walk", color: "#FFD6A5" },
  cycling: { icon: "bike", color: "#FFC4C4" },
  gaming: { icon: "gamepad", color: "#FFAFCC" },
  technology: { icon: "laptop", color: "#E7E6E1" },
  gardening: { icon: "flower", color: "#FBE7C6" },
  fashion: { icon: "tshirt-crew", color: "#FFAFCC" },
  dancing: { icon: "music-note-eighth", color: "#FFC4C4" },
  painting: { icon: "palette", color: "#FFAFCC" },
  fishing: { icon: "fish", color: "#FBE7C6" },
  knitting: { icon: "ball-of-yarn", color: "#FFD6A5" },
  writing: { icon: "fountain-pen", color: "#FFC4C4" },
  astronomy: { icon: "telescope", color: "#FFAFCC" },
  camping: { icon: "tent", color: "#FBE7C6" },
  baking: { icon: "bread-slice", color: "#FFD6A5" },
  birdwatching: { icon: "binoculars", color: "#FFC4C4" },
  diy: { icon: "toolbox", color: "#FFAFCC" },
  meditation: { icon: "meditation", color: "#FBE7C6" },
  surfing: { icon: "surfing", color: "#FFD6A5" },
  skateboarding: { icon: "skateboard", color: "#FFC4C4" },
  snowboarding: { icon: "snowboard", color: "#FFAFCC" },
  soccer: { icon: "soccer", color: "#FBE7C6" },
  basketball: { icon: "basketball", color: "#FFD6A5" },
  volleyball: { icon: "volleyball", color: "#FFC4C4" },
  tennis: { icon: "tennis", color: "#FFAFCC" },
  golf: { icon: "golf", color: "#FBE7C6" },
  running: { icon: "run-fast", color: "#FFD6A5" },
  chess: { icon: "chess-king", color: "#FFC4C4" },
  archery: { icon: "bow-arrow", color: "#FFAFCC" },
  horseRiding: { icon: "horse", color: "#FBE7C6" },
  pottery: { icon: "pot", color: "#FFD6A5" },
  rockClimbing: { icon: "rock-climbing", color: "#FFC4C4" },
  scubaDiving: { icon: "diving-scuba", color: "#FFAFCC" },
  martialArts: { icon: "karate", color: "#FBE7C6" },
  theater: { icon: "drama-masks", color: "#FFD6A5" },
  beekeeping: { icon: "bee", color: "#FFC4C4" },
  calligraphy: { icon: "pen", color: "#FFAFCC" },
  filmmaking: { icon: "filmstrip", color: "#FBE7C6" },
  boardGames: { icon: "gamepad-variant", color: "#FFD6A5" },
  coding: { icon: "code-braces", color: "#FFAFCC" },
  magic: { icon: "hat-wizard", color: "#FBE7C6" },
  wineTasting: { icon: "glass-wine", color: "#FFD6A5" },
  homebrewing: { icon: "beer", color: "#FFC4C4" },
};
