import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import { stateConnected } from "./common/store/redux_tools";
import Sidebar from "../src/components/sidebar/Sidebar";
import HomePage from "./pages/HomePage";
import UsersPage from "./pages/UsersPage";
import InsightsPage from "./pages/InsightsPage";
import SingleUserPage from "./pages/SingleUserPage";
import RequestsPage from "./pages/RequestsPage";
import NotificationsPage from "./pages/NotificationsPage";
const Routing = (props) => {
  const location = useLocation();
  return (
    <div>
      {location.pathname === "/login" ? (
        <Routes>
          <Route
            path="/login"
            element={props.auth?.token ? <Navigate to="/" /> : <Login />}
          />
        </Routes>
      ) : props.admin?.token ? (
        <Sidebar>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard/insights" />} />
            <Route path="users/requests" element={<RequestsPage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="users/manage" element={<UsersPage />} />
            <Route path="users/manage/:id" element={<SingleUserPage />} />
            <Route path="dashboard/insights" element={<InsightsPage />} />
          </Routes>
        </Sidebar>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default stateConnected(Routing);
