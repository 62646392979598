import React from "react";

const LeftIcon = (props) => {
  return (
    <svg
      height={props.size ? props.size : 20}
      width={props.size ? props.size : 20}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.color ? props.color : "currentColor"}
        d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"
      ></path>
    </svg>
  );
};

export default LeftIcon;
