import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { stateConnected } from "../common/store/redux_tools";
import OpenEye from "../common/core/icons/OpenEye";
import CloseEye from "../common/core/icons/CloseEye";
import { callApi } from "../common/utils/api";

const Login = (props) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await callApi({
      method: "POST",
      endpoint: "api/users/login",
      alert: true,
      data: { username: email, password: password },
    })
      .then((res) => {
        if (res?.token) {
          props._userLogin({ token: res.token });
          navigate("/");
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: Login.jsx:41 ~ handleSubmit ~ err:", err);
      });
  };

  return (
    <div className="flex">
      <div className="flex min-h-screen lg:w-[40%] sm:w-[50%] flex-col justify-center items-center bg-white">
        <div className="border border-indigo-400 shadow-lg w-[70%] h-[70%] flex flex-col justify-center items-center rounded-[20px] bg-white">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm w-lg w-[100%]">
            <h2 className="text-center text-[30px] font-bold leading-9 text-gray-900">
              Log In to your Account.
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-10">
              <div>
                <label
                  htmlFor="email"
                  className="block text-xl font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="user@vivah.com"
                    className="h-[50px] w-full rounded-md border-0 p-2 text-xl text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-xl font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <a
                      // href="#"
                      className="font-bold text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "password" : "text"}
                    autoComplete="current-password"
                    placeholder="********"
                    required
                    className="h-[50px] w-full rounded-md border-0 p-2 text-xl text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute top-0 right-2 p-4"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <CloseEye size={20} />
                    ) : (
                      <OpenEye size={20} />
                    )}
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="flex h-[50px] w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-1.5 text-xl   leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex min-h-screen lg:w-[60%] sm:w-[50%] flex-col justify-center bg-red-500">
        <img
          src="https://images.mid-day.com/images/images/2021/feb/marriage_d.jpg"
          alt=""
          srcset=""
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default stateConnected(Login);

// <div className="flex w-[100vw] min-h-[100vh] justify-center items-center">
//   <div className="w-[30%] border border-primary flex justify-center flex-col border p-10 shadow-[5px_5px_0px_0px_rgba(109,40,217)]">
//     <div className="sm:mx-auto sm:w-full sm:max-w-md border-primary-600  pt-16 rounded-lg">
//       <div className="flex flex-col gap-6 mb-16">
//         <h2 className="text-center text-[56px] font-bold leading-9 text-gray-800 font-lato">
//           Log in
//         </h2>
//       </div>
//       <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md flex flex-col gap-2">
//         <div>
//           <label
//             htmlFor="email"
//             className="block text-lg font-light leading-6 text-gray-900 mb-2"
//           >
//             Username
//           </label>
//           <div className="mt-1">
//             <input
//               id="email"
//               name="email"
//               type="email"
//               autoComplete="email"
//               placeholder="coordinator"
//               required
//               className="transition block w-full pl-4 h-[54px] rounded-[4px] border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-primary   focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
//               value={userName}
//               onChange={(e) => setUserName(e.target.value)}
//             />
//           </div>
//         </div>

//         <div>
//           <div className="flex items-center justify-between mt-6">
//             <label
//               htmlFor="password"
//               className="block text-lg font-light leading-6 text-gray-900 mb-2"
//             >
//               Password
//             </label>
//             <div className="text-sm">
//               <a
//                 href="#"
//                 className="text-indigo-600 hover:text-indigo-500"
//               >
//                 Forgot password?
//               </a>
//             </div>
//           </div>
//           <div className="mt-1 flex relative">
//             <input
//               id="password"
//               name="password"
//               type={showPassword ? "password" : "text"}
//               autoComplete="current-password"
//               placeholder="********"
//               required
//               className="transition block w-full pl-4 h-[54px] rounded-[4px] border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-primary   focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />

//             <button
//               className="absolute top-[2px] right-2 p-4"
//               onClick={toggleShowPassword}
//             >
//               {showPassword ? (
//                 <CloseEye size={18} />
//               ) : (
//                 <OpenEye size={18} />
//               )}
//             </button>
//           </div>
//         </div>
//         <div className="flex items-center justify-center" >
//           <button
//             type="submit"
//             onClick={handleSubmit}
//             className="bg-gradient-to-r from-indigo-400 via-cyan-500 to-cyan-500 text-white p-2 w-full rounded-lg mt-10 text-center w-[60%] m-auto"
//           >
//             <p className="text-2xl ">Log in</p>
//           </button>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
